@charset 'UTF-8';
@use './modules/global/index' as *;



// Swiper
.swiper {
  height: 100%;
}

.header-swiper-wrapper {
  position: relative;
  width: 100%;
  height: calc(200vw*640/1920);
  @include media(lg) {
    height: calc(100vw* 640 / 1920);
  }

  .header-swiper-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .swiper-inner-text {
    color: #fff;
    font-size: 1.5em;
    line-height: 2;
    padding: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0px 0px 8px #333;
    width: 100%;
    z-index: 1;
  }
}





// 重要なお知らせ
.caution-wrapper {
  background-color: $white;
  border: 2px solid $red;
  border-radius: .5em;
  display: flex;
  flex-flow: row wrap;
  padding: 1em;
  @include media(md) {
    padding: 2em;
  }
}

.caution-headline {
  margin: 0 0 1em;
  @include media(md) {
    margin: 0 2em 0 0;
  }
  h2 {
    color: $red;
    display: flex;
    align-items: center;
    font-size: 1.25em;
    margin: 0;
    &::before {
      content: '';
      background-image: set-svg($warning, $red);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: .5em;
      width: 1.25em;
      height: 1.25em;
    }
  }
}

.caution-container {
  flex: 0 0 100%;
  @include media(md) {
    flex: 1;
  }
}

ul.caution-news-list {
  width: 100%;
  li.caution-news-container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  .date {
    color: $gray-600;
    font-weight: 500;
  }

  .news-label {
    margin-left: 1em;
    .label-item {
      background-color: $gray-200;
      border-radius: 3px;
      color: $gray-dark;
      display: inline-block;
      font-size: .75em;
      line-height: 1;
      padding: .5em .5em;

      &.patients {
        background-color: $base;
        color: $white;
      }
    }
  }

  .title {
    flex: 0 0 100%;
  }

  a {
    &.news-anchor {
      display: inline-block;
      position: relative;
      transition: color $base-transition;

      @include hover() {
        color: $red;
        text-decoration: none;
      }
    }
    // icon
    &::after {
      content: '';
      background-image: set-svg($arrow-right, $gray-600);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-left: .5em;
      width: 1em;
      height: 1em;
    }
    &[target="_blank"]:not(a[href$=".pdf"]) {
      &::after {
        background-image: set-svg($open-new-window, $gray-600);
        width: 1.25em;
        height: 1.25em;
      }
    }
    &[href$=".pdf"] {
      &::after {
        background-image: set-svg($pdf, $red);
      }
    }
  }
}





// Topics バナー表示
.topics-grid-container {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat($grid-columns, 1fr);
  gap: 12px;
  @include media(lg) {
    gap: 20px;
  }

  .topics-grid-column {
    grid-column: auto / span 12;
    @include media(md) {
      grid-column: auto / span 6;
    }
  }
}

.topics {
  a {
    img {
      transition: filter .3s;
      @include hover() {
        filter: drop-shadow(0px 0px 6px #ccc);
      }
    }
  }
}





// サービスのご利用をお考えの方へ・料金表・採用情報
.top-bnr-content {
  border: solid 1px #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border-radius: 5px;
  transition: .25s;
  height: 100%;
  @include hover() {
    background-color: rgba($base, .125);
    border: 1px solid $base;
    text-decoration: none;
  }
}

.top-bnr-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 64px;
  height: 64px;
  @include media(md) {
    width: 80px;
    height: 80px;
  }

  &.service {
    background-image: set-svg($service, #479798);
  }
  &.price {
    background-image: set-svg($price, #e09037);
  }
  &.recruit {
    background-image: set-svg($recruit, #eb7099);
  }
}

.top-bnr-txt {
  font-size: .8em;
  line-height: 1.5;
  padding-top: .5em;
  text-align: center;
  @include media(md) {
    font-size: 1em;
  }
  br {
    display: none;
    @include media(md) {
      display: block;
    }
  }
}
